body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  height: auto;
  width: 100%;
  background-color: black;
}

.header .headerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bizKimiz {
  width: 100%;
  padding: 100px 100px 100px 50px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.bizKimiz span {
    height: 1px;
    width: 150px;
    background-color: black;
}

.bizKimizAcıklama {
  width: 100%;
  background-color: black;
  color: white;
  padding: 100px 100px 100px 50px;
}

.cardArea {
  margin-top: 100px;
}

.cardArea h2 {
  display: flex;
  justify-content: center;
}


/* Footer */
.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  background-color: black;
}






/* Container */

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}