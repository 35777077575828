.product {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 100px;
}

.productHeader {
    text-align: center;
}

.productContent {
    display: flex;
}

.productList {
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 30px;
}

.productImages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}